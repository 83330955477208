import { Component, OnInit } from '@angular/core';
import {
  FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterModule } from '@angular/router';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ApiAuthService } from '@core/services/api-auth.service';
import { TokenService } from '@core/services/token.service';
import { AdminData, LoginResponse } from '@core/interfaces/auth.interface';
import { MutationResult } from 'apollo-angular';
import { PasswordVisibilityDirective } from './password-visibility.directive';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { phoneNumberValidator } from './phone-number-validator';

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordVisibilityDirective,
    NgxMaskDirective,
    MatCheckboxModule,
    RouterModule,
  ],
  providers: [provideNgxMask()],
})
export class LoginComponent implements OnInit {
  public loginForm!: FormGroup;

  errorMessage: string = '';

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly apiAuthService: ApiAuthService,
    private readonly tokenService: TokenService,
  ) {

  }

  ngOnInit(): void {
    this.createForm();
  }

  public createForm(): void {
    this.loginForm = this.fb.group({
      phone: new FormControl('', [Validators.required,phoneNumberValidator()]),
      password: new FormControl('', Validators.required),
    });
  }

  public onSubmitForm(): void {
    const { phone, password } = this.loginForm.value;
    const phoneNumber = `380${phone.toString()}`;
    const passwordNumber = password.toString();

    this.apiAuthService.login(phoneNumber, passwordNumber).pipe(untilDestroyed(this)).subscribe(
      (res: MutationResult<{ login: LoginResponse }>) => {
        if (res) {
          this.tokenService.setAccesstToken(res.data!.login.accessToken);
          this.tokenService.setRefreshToken(res.data!.login.refreshToken);
          this.apiAuthService.getAdminData().pipe(untilDestroyed(this)).subscribe((adminRes: MutationResult<{ getAdminData: AdminData }>) => {
            if (!adminRes.data!.getAdminData.isTemporaryPassword) {
              this.router.navigateByUrl('/dashboard');
            } else {
              this.apiAuthService.oldPasswordData = password;
              this.router.navigate(['/auth/change-password'], { queryParams: { from: '/auth' } });
            }
          });
        }
      },
      (res) => {
        this.errorMessage = 'Incorrect email address or password.';
        const phoneControl = this.loginForm.get('phone');
        const passwordControl = this.loginForm.get('password');
          phoneControl!.setErrors({ incorrect: true });
          passwordControl!.setErrors({ incorrect: true });
      },
    );
  }
}
