import { Routes } from '@angular/router';
import { LoginGuard } from '@core/guards/login.guard';
import { MainComponent } from './modules/main/main.component';
import { LoginComponent } from './modules/login/login.component';
import { ForgotPasswordComponent } from './modules/login/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './modules/login/change-password/change-password.component';
import { CheckAutorization } from '@core/guards/check-autoriation.guard';
import { AuthChangePasswordGuard } from '@core/guards/auth-change-password.guard';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/main/main.routing').then((c) => c.mainRoutes),
    canActivate: [LoginGuard],
  },
  {
    path: 'auth',
    component: LoginComponent,
    canActivate: [CheckAutorization],
  },
  {
    path: 'auth/forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [CheckAutorization],
  },
  {
    path: 'auth/change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthChangePasswordGuard],
  },
];
