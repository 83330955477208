<div class="wrapper">
  <div class="container">
    <div class="header">
      <img src="/assets/icons/shared-icons/logo.svg" alt="logo">
    </div>
    <div class="auth-wrapper">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmitForm()">
        <p class="title">{{'auth_title_header'}}</p>
        <div class="auth-container">
          <div class="number-container">
            <p class="input-info">{{('auth_title_')}}</p>
            <mat-form-field class="number-form-field">
              <input matInput formControlName="phone" prefix="+380 " mask="(00) 000 00 00" [showMaskTyped]="true"
                class="login-input" type="text" placeholder="(000) 000 00 00">
            </mat-form-field>
            @if(loginForm.get('phone')?.hasError('required') && loginForm.get('phone')?.dirty){
            <mat-error>
              Phone number is required
            </mat-error>
            }
            @if(loginForm.get('phone')?.hasError('invalidPhoneNumber') && loginForm.get('phone')?.dirty){
            <mat-error>
              Invalid phone number pattern
            </mat-error>
            }
          </div>
          <div class="password-container">
            <p class="input-info">{{('auth_title')}}</p>
            <mat-form-field appPasswordViasability class="password-form-field">
              <input matInput formControlName="password" class="password-input" type="text" placeholder="Пароль">
            </mat-form-field>
          </div>
          @if(errorMessage){
          <mat-error>
            {{errorMessage}}
          </mat-error>
          }
          <div class="action-container">
            <div class="checkbox-wrapper">
              <mat-checkbox>{{('auth_rememver_me')}}</mat-checkbox>
            </div>
            <a [routerLink]="'/auth/forgot-password'" class="forgot-password-link">
              {{('auth_title_forgot_password')}}
            </a>
          </div>
        </div>
        <button class="submit" [disabled]="!loginForm.valid" mat-button>{{'auth_submit'}}</button>
      </form>
    </div>
  </div>
</div>