import { Injectable } from '@angular/core';
import {
  CanActivate, Router, UrlTree,
} from '@angular/router';
import { TokenService } from '@core/services/token.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly tokenService: TokenService,
  ) { }

  canActivate(): Observable<boolean | UrlTree> {
    return this.tokenService.getAccessToken()
      .pipe(
        take(1),
        map((token) => {
          if (token) {
            return true;
          }
          return this.router.createUrlTree(['/auth']);
        }),
      );
  }
}
