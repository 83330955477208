import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderService } from '@core/services/loader.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  standalone: true,
  imports: [MatProgressSpinnerModule, CommonModule],
})
export class SpinnerComponent{

  public divsArray: number[] = Array(9).fill(0).map((_, index) => index + 1);

  constructor(public loader: LoaderService) { }
}
