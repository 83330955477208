import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { AuthInterceptor } from '@core/interceptors/auth-interceptor';
import { LoaderInterceptor } from '@core/interceptors/loader-interceptor';
import { routes } from './app.routes';
import { graphqlProvider } from './graphql.provider';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    // provideAnimations(),
    provideAnimationsAsync('animations'),
    provideEnvironmentNgxMask(maskConfig), provideHttpClient(), graphqlProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
  ],
};
